<template>
  <div class="launcher">
    <div class="category">
      <b>{{ category.CATEGORY.name }} - {{ category.LEVEL.LEVEL.name }}</b>
      <b-button @click="edit" size="sm" variant="outline-secondary">Modif.</b-button>
    </div>
    <div class="events">
      <b-button
        v-for="event in events"
        :key="event.code"
        class="event"
        @click="launchEvent(event, category)"
      >
        {{ event.EVENT.name }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {  SEASON_RULE_EVENT } from "@/LocalDB";

export default {

  props:{
    category: { type: Object, required: true}, // value correspond à une catégorie (RST_CATEGORY)
  },
  computed:{

    events(){
      var ret = (this.category.RULE != null)
              ? SEASON_RULE_EVENT.query()
                    .where("competition_type", this.category.COMPETITION.competition_type)
                    .where("year", this.category.COMPETITION.year)
                    .where("level", this.category.level)
                    .where("rule", this.category.RULE.code)
                    .get()
              : [];

      return ret;
    },

  },
  methods:{
    edit(){
      this.$emit("edit", this.category);
    },
    launchEvent(event){
      var send = {
        competition_id: this.category.competition_id,
        round_number: this.category.round_number,
        level: this.category.level,
        category: this.category.code,
        event: event.EVENT.code,
        EVENT: event.EVENT,
      }
      console.log('LaunchEvent', send);
      this.$emit("prepareEvent", send);
    }
  }
}
</script>

<style scoped>
  .launcher{
    height: 200px !important;
    width: 300px !important;
    margin: 10px;
    border: solid 1px var(--ffvlOrangePicto );
  }
  .event{
    width: 70%;
    height: 50px;
  }
  .events{
    display:flex;
    height: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;;
  }
  .category{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border: solid 1px var(--ffvlOrangePicto);
    margin: -1px;
    margin-right:-2px;
    margin-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }
</style>
